import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Carousels from '../../components/carousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faLaptop, faPerson } from '@fortawesome/free-solid-svg-icons'
import { Link, graphql } from 'gatsby'
import { matchOrganizationByLevel } from '../../helpers/matchOrganizationByLevel'
import { useForm } from '../../hooks/useForm'
import { searchCareer } from '../../helpers/searchCareer'
import coverFeeds from '../../helpers/carrerCoverFeeds'
import { CAREER_GENERAL_ROUTE } from '../../utils/variables/generalRoutes'

const HealthSpeciality = ({ data, pageContext }) => {
  const [organizationsByCareers, setOrganizationsByCareers] = useState([])
  
  // Filters
  const [valuesFilter, handleInputChange] = useForm({inputSearch: ''})
  const { inputSearch } = valuesFilter

  const { careerLevelName, mainNavbar, filterVirtualCareers } = pageContext

  const {
    strapiInstitution: institution,
    allStrapiCareerCategory: careerCategories,
    allStrapiUccCareer: uccCareers,
    allStrapiUccCareerPlaces: uccCareerPlaces,
  } = data

  const objCarousel = {
    visible: true,
    slide: coverFeeds(careerLevelName, institution)
  }

  const page = {
    slug: null,
    ...mainNavbar
  }
  useLayoutEffect(() => {
    setOrganizationsByCareers(matchOrganizationByLevel(data))
  }, [])

  const categories = careerCategories.nodes

  return (
    <div>
      <Layout page={page} logo={page.logo} header={page.header}>
        <Seo title={'Especialidades de Ciencias de Salud'} />
        <section className="schedule-feed mb-3">
          {objCarousel?.slide ? (
            <div className="d-flex flex-column schedule-header-carousel">
              <Carousels carousel={objCarousel} keyName={'scheduleFeed-'} />
            </div>
          ) : null}
          <div className="container">
            <div className="my-5">
              <div className="d-flex align-items-center justify-content-center row mb-3">
                <div className="col-12 col-md-8 mb-auto">
                  {careerLevelName ? (
                    <h5 className="text-uppercase title-secondary font-weight-bold">
                      Carreras de{' '}
                      <span className="font-weight-bold text-main">
                        {careerLevelName}
                      </span>
                    </h5>
                  ) : (
                    <h5 className="text-uppercase title-secondary font-weight-bold">
                      Especialidades de Ciencias de Salud
                    </h5>
                  )}

                  <hr className="my-2" style={{ backgroundColor: '#f7f7f7' }} />
                </div>
                <form className="col-12 col-md-4 pb-1">
                  <div className="d-flex feed-form">
                    <div className="input-group">
                      <div className="input-group-prepend ">
                        <span
                          className=" form-control form-control-sm input-group-text"
                          id="basic-addon1"
                        >
                          <FontAwesomeIcon icon={faSearch} size="sm" />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Buscar..."
                        aria-label="Buscar..."
                        aria-describedby="basic-addon1"
                        name="inputSearch"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className=" row d-flex align-items-stretch">
                {organizationsByCareers.length == 0 ? (
                  <h4 className="mt-5 col">
                    No se encontraron carreras de {careerLevelName} disponibles
                  </h4>
                ) : (
                  searchCareer(
                    organizationsByCareers,
                    inputSearch,
                    categories
                  ).map((organization) => {
                    return feedCarrerCreator(
                      organization,
                      categories,
                    )
                  })
                )}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

const getCareerIcon = (career) => {
  if (career.modality === 'Distancia') {
    return (
      <div className="iconContainer">
        <FontAwesomeIcon icon={faLaptop} />
      </div>
    )
  } else if (career.modality === 'Ambas') {
    return (
      <div className="iconContainer">
        <FontAwesomeIcon icon={faPerson} />
        <FontAwesomeIcon icon={faLaptop} />
      </div>
    )
  } else {
    return null
  }
}

// Creando el Feed con sus carreras
const feedCarrerCreator = (organization, categories) => {
  const filteredCareers = organization.careers.filter((career) => {
    const category = categories.find(
      (cat) => cat.strapiId === career.career_category.id
    )
    return category?.name === 'Especialidad Cs Salud'
  })

  if (filteredCareers.length > 0) {
    return (
      <div
        className={'mb-4 col-12'}
        key={`key-feed-career-${organization.name}`}
      >
        <div className="card h-100 shadow-sm fade-in-image">
          {/* Portada de OU */}
          <Link
            to={`/${organization.slug}`}
            target="_blank"
            className="disable__link__styles"
          >
            <div
              className="w-100 news-image card-img-top d-flex flex-column align-items-end cursor-pointer "
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${
                  organization.coverImg
                    ? organization.coverImg.url
                    : 'https://images.pexels.com/photos/159490/yale-university-landscape-universities-schools-159490.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
                }")`
              }}
            >
              <div className="card-body d-flex align-items-end align-self-start">
                <h4 className="card-title font-weight-bold  text-white text-uppercase">
                  {organization.name}
                </h4>
              </div>
              <div
                className="w-100"
                style={{
                  backgroundColor: organization.color,
                  height: '8px'
                }}
              ></div>
            </div>
          </Link>

          {/* Listando carreras */}
          <ul className="list-group list-group-flush list-group-variant">
            {organization.careers
              ?.sort(function (a, b) {
                return a.name.localeCompare(b.name)
              })
              .filter((career) => {
                const category = categories.find(
                  (cat) => cat.strapiId === career.career_category.id
                )

                return category?.name === 'Especialidad Cs Salud'
              })
              .map((career) => {
                return career.slug ? (
                  <Link
                    to={`/${CAREER_GENERAL_ROUTE}/${career.slug}`}
                    className="list-group-item list-group-item-variant cursor-pointer fade-in-image text-decoration-none"
                    key={`career-key-${career.name}`}
                    target={'_self'}
                  >
                    {career.name}{getCareerIcon(career)}
                  </Link>
                ) : null
              })}
          </ul>
        </div>
      </div>
    )
  }
}

export default HealthSpeciality

export const allCareerQuery = graphql`
  query($careerlevelId: Int) {
    allStrapiIcdaCareer {
      nodes {
        name
        slug
        main {
          headerImages {
            image {
              url
            }
          }
        }
        career_category {
          name
        }
      }
    }
    allStrapiCareerCategory(
      filter: { career_level: { id: { eq: $careerlevelId } } }
    ) {
      nodes {
        strapiId
        name
        career_level {
          id
          name
        }
      }
    }
    allStrapiOrganizationalUnit {
      nodes {
        id
        name
        color
        slug
        ucc_careers {
         id
        }
        header {
          slide {
            backgroundImage {
              url
            }
          }
        }
      }
    }
    allStrapiUccCareer {
      nodes {
        strapiId
        name
        slug
        career_category {
          id
        }
        organizational_unit {
          id
        }
        ucc_career_places {
          title
        }
      }
    }
    allStrapiUccCareerPlaces {
      nodes {
        strapiId
        title
        colorHex
      }
    }
    strapiInstitution {
      careersCovers {
        gradoCareerCover {
          content
          imageDescription
          name
          url
          visible
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
        posgradoCareerCover {
          content
          imageDescription
          name
          url
          visible
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
        pregradoCareerCover {
          content
          imageDescription
          name
          url
          visible
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
    }
  }
`

HealthSpeciality.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object
}
